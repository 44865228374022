<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="evaluators"
      :sort-mapping="sortMapping"
      :title="'evaluators'"
      :total="total"
      :fetch-path="'convo/fetchEvaluators'"
      :export-path="'convo/exportEvaluators'"
      :filter-path="'convo/filterEvaluators'"
      :evaluators-path="'convo/assignEvaluators'"
      :actions="true"
      @selectedItems="saveSelectedItems"
      @searchEvaluators="searchEvaluators"
      @selectEvaluators="selectEvaluators"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <strong><span class="ps-2">Assign group</span></strong>
              <b-dropdown-item
                v-for="area in convo.areas"
                :key="area.id"
                @click="assignToGroup(area, field)"
              >
                <i
                  data-feather="folder"
                  class="me-50"
                /> {{ area.areaName }}
              </b-dropdown-item>
              <div class="dropdown-divider" />
              <b-dropdown-item @click="removeEvaluator(area, field)">
                <i
                  data-feather="delete"
                  class="me-50"
                /> Remove evaluator
              </b-dropdown-item>
              <div class="dropdown-divider" />
              <b-dropdown-item @click="$router.push({name: 'admin.users.edit', params: { id: field.id }})">
                <i
                  class="me-50"
                  data-feather="edit"
                />
                Edit
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <a
            href=""
            class="btn btn-icon btn-light-secondary d-block d-sm-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasBottom"
          ><i
            data-feather="more-vertical"
          /></a>
        </td>
      </template>
      <template #customfilters>
        <div class="mb-1">
          <label
            for="nameseniorcall"
            class="form-label"
          >Name</label>
          <input
            type="text"
            class="form-control"
            name="nameseniorcall"
            @change="saveFilter($event.target.value, 'name')"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Group</label>
          <v-select
            label="areaName"
            :options="convo.areas"
            :get-option-key="option => option.areaId"
            :reduce="option => option.code"
            @input="saveFilter($event, 'area')"
          />
        </div>
      </template>
      <template #info-sheet-item>
        <div class="offcanvas-body offcanvas-body--view">
          <h4 class="mb-1">
            <strong>Add evaluator</strong>
            <span
              class="mb-1 text-right"
              style="float: right;"
            >
              <a
                class="btn btn-icon btn-primary float-right"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add Senior call"
                @click="$router.push({name: 'admin.users.create'})"
              ><i
                data-feather="plus"
              /></a>
            </span>
          </h4>
          <div class="mb-1">
            <label
              for="nameseniorcall"
              class="form-label"
            >Name</label>
            <v-select
              v-model="selectedEvaluators"
              label="name"
              multiple
              :placeholder="'Type 3 or more characters to search...'"
              :options="evaluatorsTotal"
              :get-option-key="option => option.id"
              @search="onSearch($event, 'users/fetchEvaluators')"
            />
          </div>
          <div class="row">
            <div class="mb-1 col-7">
              <label
                for=""
                class="form-label"
              >Area assigned to call</label>
              <v-select
                v-model="area"
                label="name"
                :options="areasSelect"
                :get-option-key="option => option.id"
              />
            </div>
            <div class="form-check form-check-success form-switch d-flex align-items-center mb-1 col-5">
              <input
                id="publishContent"
                v-model="moderator"
                type="checkbox"
                class="form-check-input"
              >
              <label
                class="form-check-label"
                for="publishContent"
              ><small class="ms-1">Mark as moderator</small></label>
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <a
              href="#"
              title="apply actions"
              class="btn btn-primary mb-1 d-grid w-100"
              @click="applyActions"
            >Apply actions</a>
          </div>
        </div></template>
      <template #info-sheet-test>
        <div class="offcanvas-body offcanvas-body--view">
          <h4 class="mb-1">
            <strong>Send credentials to:</strong>
          </h4>
          <div class="mb-1">
            <div class="form-check">
              <input
                id="flexCheckDefault"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                checked
                @click="validateChecks"
              >
              <label
                class="form-check-label"
                for="flexCheckDefault"
              >All evaluators</label>
            </div>
          </div>
          <div class="mb-1">
            <div class="form-check">
              <input
                id="flexCheckDefault"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                @click="toAllEvaluatos = !toAllEvaluatos"
              >
              <label
                for="nameseniorcall"
                class="form-label"
              >Select evaluators</label>
              <v-select
                v-if="!toAllEvaluatos"
                v-model="recipients"
                multiple
                label="name"
                :options="evaluators.filter(o => recipients.filter(e => e.id == o.id).length == 0)"
                :get-option-key="option => option.id"
              />
            </div>
          </div>
        </div>

        <div class="offcanvas-footer mt-auto">
          <button
            v-if="!sending"
            type="button"
            class="btn btn-primary mb-1 w-100"
            @click="sendCredentials"
          >
            <span class="me-25 align-middle">Send credentials</span>
            <i data-feather="send" />
          </button>
          <b-button
            v-else
            type="submit"
            disabled
          >
            <div class="loading">
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </b-button>
        </div>
      </template>
    </CompleteTable>
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
    applicants: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      area: null,
      selectedEvaluators: [],
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Group',
          checked: true,
          order: 3,
        },
        {
          name: 'Feedback Editor',
          checked: true,
          order: 5,
        },
        {
          name: 'ERC Codes',
          checked: true,
          order: 6,
        },
        {
          name: 'AVG Mark',
          checked: true,
          order: 7,
        },
        {
          name: 'Num Academia calls',
          checked: true,
          order: 8,
        },
        {
          name: 'Num Senior calls',
          checked: true,
          order: 9,
        },
        {
          name: 'Last access',
          checked: true,
          order: 10,
        },
      ],
      sortMapping: {
        Name: 'name_moderator',
        Area: 'user_area.code',
        Group: 'area.code',
        'Feedback Editor': 'feedback_editor',
        'ERC Codes': 'erc.code',
        'AVG Mark': 'avg_grade',
        'Num Academia calls': 'num_academia_calls',
        'Num Senior calls': 'num_senior_calls',
        'Last access': 'last_access',
      },
      selectedItems: [],
      loading: true,
      toAllEvaluatos: true,
      recipients: [],
      sending: false,
      moderator: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      evaluators: 'convo/evaluators',
      total: 'convo/evaluatorsTotal',
      applicantData: 'convo/applicants',
      evaluatorsTotal: 'users/evaluators',
      fields: 'modals/tableSettingsFields',
      ercs: 'erc/items',
    }),
    data() {
      return {
        data: this.evaluators,
        total: this.evaluators.length,
      }
    },
    areasSelect() {
      let areas = []
      if (this.selectedEvaluators.length > 1) {
        areas = this.convo.areas.filter(e => {
          if (this.convo.areas.some(f => f.parent_id === e.areaId)) {
            return false
          }

          return true
        })
      } else if (this.selectedEvaluators.length === 1) {
        this.selectedEvaluators[0].areas.forEach(e => {
          if (this.convo.areas.some(f => f.areaId === e.id)) {
            if (this.convo.areas.some(f => f.parent_id === e.id)) {
              e.childrens.forEach(c => {
                if (this.convo.areas.some(f => f.areaId === c.id)) {
                  areas.push(c)
                }
              })
            } else {
              areas.push(e)
            }
          }
        })
      }

      return areas
    },
  },
  async mounted() {
    // this.$store.dispatch('users/fetchEvaluators')
    this.$store.dispatch('modals/fetchUserFields', 'evaluators_list')
    this.$store.dispatch('erc/fetch')
    this.loading = false

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'evaluators_list',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    assignToGroup(area, field) {
      this.area = area
      this.selectedEvaluators = []
      this.selectedEvaluators.push(field)
      this.applyActions()
    },
    removeEvaluator(area, field) {
      Vue.swal({
        title: 'Do you want to remove this evaluator?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('convo/assignEvaluators', {
            convoId: this.convo.id,
            area,
            evaluators: [field],
            remove: true,
          })
        }
      })
    },
    applyActions() {
      if (this.selectedEvaluators.length > 0) {
        Vue.swal({
          title: 'Do you want to add this evaluators?',
          html: `Assign evaluators to the call ${this.convo.title}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('convo/assignEvaluators', {
              convoId: this.convo.id,
              area: this.area,
              evaluators: this.selectedEvaluators,
              moderator: this.moderator,
            })
            this.selectedEvaluators = []
            this.area = null
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
          }
        })
      } else {
        this.$toastr.error('', 'Please, select evaluator before applying')
      }
    },
    async onSearch(search, store) {
      if (search !== '' && search.length > 2) {
        await this.$store.dispatch(store, { name: search })
      }
    },
    validateChecks() {
      if (!this.toAllEvaluatos) {
        this.toAllEvaluatos = true
        this.recipients = []
      }
    },
    sendCredentials() {
      this.sending = true
      if ((!this.toAllEvaluatos && this.recipients.length > 0) || this.toAllEvaluatos) {
        this.$store.dispatch('convo/sendEvaluatorCredentials', {
          recipients: this.recipients,
          toAllEvaluators: this.toAllEvaluatos,
          convoId: this.convo.id,
        }).then(() => {
          this.sending = false
          this.$toastr.success('', 'Credentials sended')
          this.$store.dispatch('modals/toggleInfoItemSheet', false)
        }).catch(() => {
          this.sending = false
          this.$toastr.error('', 'Error on send credentials')
        })
      } else {
        this.sending = false
        this.$toastr.error('', 'Select evaluators to send credentials')
      }
    },
    searchEvaluators(data) {
      console.log(data)
    },
    selectEvaluators(data) {
      console.log(data)
    },
  },
}
</script>
