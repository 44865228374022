<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Groups for {{ convo.id ? `${convo.title}` : '' }}<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to Academia Call list"
              @click="$router.push({name: 'admin.calls.senior.index'})"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <b-tabs lazy>
              <b-tab
                active
              >
                <template #title>
                  List of evaluators
                </template>

                <GroupTable />
              </b-tab>
              <b-tab>
                <template #title>
                  List of applicants
                </template>

                <ApplicantTable />
              </b-tab>
              <b-tab>
                <template #title>
                  Assign applicants
                </template>

                <EvaluatorsTable />
              </b-tab>
              <b-tab>
                <template #title>
                  Assign feedback editors
                </template>

                <FeedbackEditors />
              </b-tab>
            </b-tabs>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import GroupTable from '../academy-call/components/GroupTable.vue'
import ApplicantTable from '../academy-call/components/ApplicantTable.vue'
import EvaluatorsTable from '../academy-call/components/EvaluatorsTable.vue'
import FeedbackEditors from '../academy-call/components/FeedbackEditors.vue'

export default {
  components: {
    GroupTable,
    EvaluatorsTable,
    BTabs,
    BTab,
    FeedbackEditors,
    ApplicantTable,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
      },
      convoId: this.$route.params.id,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      sended: 'convo/sended',
      loggedUser: 'auth/admin',
      evaluators: 'convo/evaluators',
    }),
  },
  async mounted() {
    if (this.convoId && this.convoId !== this.convo.id) {
      await this.$store.dispatch('convo/fetchSpecific', this.convoId)
    }

    await this.$store.dispatch('convo/fetchEvaluators', this.$route.params.id)

    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    updateCallStatus() {
      this.$store.dispatch('users/updateStatus', { id: this.loggedUser.id, status: this.loggedUser.call_status })
    },
  },
}
</script>
