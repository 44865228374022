<template>
  <div>
    <div class="card card--table">
      <div
        class="card-body"
        :class="{'d-none': loading}"
      >
        <div
          class="row"
        >
          <div class="col-sm-6">
            <h4><strong>{{ pendingEvaluators }} / {{ evaluatorsTotal }}</strong> feedback editors</h4>
            <div class="form-check form-check-success form-switch d-flex align-items-center">
              <input
                id="publishContent"
                type="checkbox"
                class="form-check-input"
                @click="hideAssigneds"
              >
              <label
                class="form-check-label"
                for="publishContent"
              ><small class="ms-1">Hide assigneds </small></label>
              <template v-if="area && area.length > 0">
                <input
                  id="publishContent1"
                  v-model="showEvaluators"
                  type="checkbox"
                  class="form-check-input ms-2"
                  value="1"
                >
                <label
                  class="form-check-label"
                  for="publishContent1"
                ><small class="ms-1">Show evaluators </small></label>
              </template>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="">
              <label
                for=""
                class="form-label"
              >Filter by area</label>
              <v-select
                v-model="area"
                multiple
                label="areaName"
                :options="convo.areas"
                :get-option-key="option => option.areaId"
                @input="filter"
              />
            </div>
          </div>
        </div>
        <hr>
        <div
          v-if="showEvaluators && area && area.length > 0"
          class="stats__evaluators"
        >
          <ul class="list-group list-group-horizontal-sm">
            <li
              v-for="evaluator in evaluators"
              :key="evaluator.id"
              class="list-group-item"
            >
              {{ evaluator.name }}
              <span class="badge badge-light-success ms-1">
                {{ evaluator.hasApplicants }}
              </span>
              <br>
              <span
                v-if="evaluator.area"
                class="badge bg-light-secondary"
              >
                {{ evaluator.area ? evaluator.area.code : '-' }}
              </span>
              <span class="badge bg-light-secondary ms-1">
                {{ evaluator.erc ? evaluator.erc.code : '-' }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>

    <FeedbackEditorApplicant
      v-for="applicant in applicants"
      :key="applicant.user_id"
      :applicant="applicant"
      :visible-all="visibleAll"
      :area="area"
    />

    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <Paginator
          :total="applicantsTotal"
          :store="'convo/fetchApplicants'"
          @loading="setLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Paginator from '@/views/back/partials/Paginator.vue'
import FeedbackEditorApplicant from './FeedbackEditorApplicant.vue'

export default {
  components: {
    vSelect,
    FeedbackEditorApplicant,
    Paginator,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
  },
  data() {
    return {
      loading: true,
      showEvaluators: false,
      area: null,
      visibleAll: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      evaluators: 'convo/evaluators',
      evaluatorsTotal: 'convo/evaluatorsTotal',
      applicantsTotal: 'convo/applicantsTotal',
      applicants: 'convo/applicants',
    }),
    pendingEvaluators() {
      return this.evaluators.filter(e => e.hasApplicants !== 0).length
    },
  },
  async mounted() {
    this.resetCard()
  },
  methods: {
    async resetCard() {
      await this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 996 } })
      this.loading = false
    },
    async filter() {
      this.loading = true
      this.saveFilter(this.area, 'area')
      this.saveFilter({ value: 996 }, 'status')
      this.$store.dispatch('convo/fetchApplicants', { area: this.area, status: { value: 996 } })
      await this.$store.dispatch('convo/filterEvaluators', { area: this.area })
      this.showEvaluators = true
      this.loading = false
    },
    hideAssigneds(event) {
      if (event.target.checked) {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 998 } })
      } else {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 996 } })
      }
    },
    setLoading(loading) {
      this.loading = loading
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
  },
}
</script>
