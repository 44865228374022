<template>
  <div>
    <div class="card card--table">
      <div
        class="card-body"
        :class="{'d-none': loading}"
      >
        <div
          class="row"
        >
          <div class="col-sm-4">
            <h4><strong>{{ pendingEvaluators }} / {{ evaluatorsTotal }}</strong> evaluators</h4>
            <div class="form-check form-check-success form-switch d-flex align-items-center">
              <input
                id="publishContent"
                type="checkbox"
                class="form-check-input"
                @click="hideAssigneds"
              >
              <label
                class="form-check-label"
                for="publishContent"
              ><small class="ms-1">Hide assigneds </small></label>
              <template v-if="area && area.length > 0">
                <input
                  id="publishContent1"
                  v-model="showEvaluators"
                  type="checkbox"
                  class="form-check-input ms-2"
                  value="1"
                >
                <label
                  class="form-check-label"
                  for="publishContent1"
                ><small class="ms-1">Show evaluators</small></label>
              </template>
              <button
                class="btn btn-dark ms-2"
                @click="syncronize()"
              >
                Syncronize
              </button>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-4">
                <div class="">
                  <label
                    for=""
                    class="form-label"
                  >Filter by area</label>
                  <v-select
                    v-model="area"
                    multiple
                    label="areaName"
                    :options="convo.areas"
                    :get-option-key="option => option.areaId"
                    @input="filter"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label
                    for=""
                    class="form-label"
                  >Filter by evaluator</label>
                  <v-select
                    label="name"
                    :options="evaluators"
                    :get-option-key="option => option.id"
                    @input="filterEvaluator"
                    @search="onSearch({ name: $event }, 'convo/filterEvaluators')"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Candidate</label>
                  <v-select
                    :filter="customFilter"
                    label="full_name"
                    :options="applicants"
                    :get-option-key="option => option.id"
                    :reduce="e => e.user_id"
                    @input="filterCandidate"
                    @search="onSearch({ candidate: $event }, 'convo/fetchApplicants')"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        <hr>
        <div
          v-if="showEvaluators && area && area.length > 0"
          class="stats__evaluators"
        >
          <ul class="list-group list-group-horizontal-sm">
            <li
              v-for="evaluator in evaluators"
              :key="evaluator.id"
              class="list-group-item"
            >
              {{ evaluator.name }}
              <span class="badge badge-light-success ms-1">
                {{ evaluator.assignedApplicants }}
              </span>
              <br>
              <span
                v-if="evaluator.area"
                class="badge bg-light-secondary"
              >
                {{ evaluator.area ? evaluator.area.code : '-' }}
              </span>
              <span class="badge bg-light-secondary ms-1">
                {{ evaluator.erc ? evaluator.erc.code : '-' }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="row"
    >
      <div class="col">
        <div class="viewform__actions--buttons text-start pb-2">
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Display all blocks"
            @click="reorderAlphabetical(true)"
          ><i data-feather="arrow-down" /> Order A-Z</a>
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Collapse all blocks"
            @click="reorderAlphabetical(false)"
          ><i data-feather="arrow-up" /> Order Z-A</a>
        </div>
      </div>
      <div class="col">
        <div class="viewform__actions--buttons pb-2">
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Display all blocks"
            @click="showAll(true)"
          ><i data-feather="maximize-2" /> Display all</a>
          <a
            class="btn btn-sm btn-flat-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Collapse all blocks"
            @click="showAll(false)"
          ><i data-feather="minimize-2" /> Collapse all</a>
        </div>
      </div>
    </div>
    <div
      v-for="applicant in applicants"
      :key="applicant.user_id"
      class="card card--table"
    >
      <div
        v-b-toggle="`collapseApplicant-${applicant.user_id}`"
        class="card-header"
      >
        <h3 class="cart-title">
          <a data-action="collapse">{{ applicant.full_name }} </a>
        </h3>
        <div class="heading-elements d-flex">
          <span
            v-if="applicant.evaluators.length > 0"
            class="badge bg-light-success me-2"
          >Assigned</span>
          <span
            v-else
            class="badge bg-light-secondary me-2"
          >Pending</span>
          <span
            class="badge me-2"
            :class="{'bg-light-success': applicant.evaluators.length > 2, 'bg-light-danger': applicant.evaluators.length < 3}"
          >{{ applicant.evaluators.length }}</span>
          <ul class="list-inline mb-0">
            <li>
              <a data-action="collapse"><i data-feather="chevron-down" /></a>
            </li>
          </ul>
        </div>
      </div>
      <b-collapse
        :id="`collapseApplicant-${applicant.user_id}`"
        :visible="visibleAll"
      >
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <table class="table mb-2">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Host institution</th>
                      <th>ERC CODE</th>
                      <th>AVG Grade</th>
                      <th>AVG Normalize</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ applicant.area }}</td>
                      <td>{{ applicant.institution }}</td>
                      <td>{{ applicant.erc }}</td>
                      <td>{{ applicant.avg_grade }}</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-sm-6">
                    <p><strong>Research interest</strong></p>
                    <p><small v-html="applicant.research_interests ? applicant.research_interests.replace(/<[^>]*>/g, '') : ''" /></p>
                  </div>
                  <div class="col-sm-6 container__keywords">
                    <p><strong>Keywords</strong></p>
                    <template v-if="applicant.keywords">
                      <span
                        v-for="keyword in applicant.keywords.replace(/<[^>]*>/g, '').split(/[\/,\;\.]/)"
                        :key="keyword"
                        class="badge bg-dark me-1"
                      >{{ keyword }}</span>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <ul class="list-group">
                  <li
                    v-for="evaluator in applicant.evaluators"
                    :key="evaluator.id"
                    class="list-group-item d-flex align-items-center"
                  >
                    <label
                      class="form-check-label text-dark"
                      for="flexRadioDefault1"
                    >
                      <strong>{{ evaluator.name }}</strong>
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="delete evaluator"
                      >
                        <a
                          class="btn text-danger"
                          @click="removeEvaluator(applicant, evaluator.id)"
                        >
                          <i data-feather="trash-2" />
                        </a>
                      </span>
                      <div class="mt-1 container__keywords">
                        <span class="badge bg-secondary me-1">{{ evaluator.erc ? evaluator.erc.code : '-' }}</span>
                        <span
                          v-for="keyword in evaluator.keywords"
                          :key="keyword.id"
                          class="badge bg-dark me-1"
                        >{{ keyword.word }}</span>
                      </div>
                    </label>
                    <span
                      class="badge bg-light-success rounded-pill ms-auto"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Number of applicants assigned"
                    >{{ evaluator.assignedApplicants }}</span>
                  </li>
                </ul>
                <div class="text-end mt-2">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasSelectEvaluators"
                    aria-controls="offcanvasSelectEvaluators"
                    class="btn btn-sm btn-dark"
                    @click="editSingle(applicant)"
                  ><i data-feather="plus" /> Add evaluator</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <ItemInfoSheet>
      <template #info-sheet-title>
        Select evaluators
      </template>
      <template #info-sheet>
        <div class="offcanvas-body">
          <h4>{{ selectedItem.full_name }}</h4>
          <hr>
          <div class="mb-1">
            <label
              for=""
              class="form-label"
            >Filter by area or subarea</label>
            <select
              id=""
              name=""
              class="form-select select2"
              @change="filterEval($event.target.value)"
            >
              <option
                :value="selectedArea.code"
              >
                {{ selectedArea.name }}
              </option>
              <option
                v-for="area in selectedArea.childrens"
                :key="area.id"
                :value="area.code"
                :selected="area.code === selectedItem.area"
              >
                {{ area.name }}
              </option>
            </select>
          </div>
          <hr>
          <div class="mb-2">
            <a
              class="me-2 text-dark"
              @click="selectEvaluators(true)"
            >Select all</a>
            <a
              class="text-dark"
              @click="selectEvaluators(false)"
            >Unselect all</a>
          </div>

          <div class="mb-1">
            <h5>{{ filteredEvaluators.length }} evaluators</h5>
            <ul
              v-if="!loadingEvaluators"
              id="basic-list-group"
              class="list-group"
            >
              <li
                v-for="evaluatorSel in filteredEvaluators"
                :key="evaluatorSel.id"
                class="list-group-item"
              >
                <div class="d-flex">
                  <div class="more-info w-100">
                    <div class="form-check">
                      <input
                        v-model="selectedEvaluators"
                        type="checkbox"
                        class="form-check-input"
                        :value="evaluatorSel.id"
                      >
                      <label
                        class="form-check-label"
                      >{{ evaluatorSel.name }} <span class="badge bg-light-secondary float-end ms-1">{{ evaluatorSel.erc ? evaluatorSel.erc.code : '-' }}</span></label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div
              v-if="loadingEvaluators"
            >
              <div
                class="loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
          </div>
        </div>
        <div class="offcanvas-footer mt-auto">
          <a
            href="#"
            title="apply actions"
            class="btn btn-primary mb-1 d-grid w-100"
            @click="applyActions"
          >Assign</a>
        </div>

      </template>
    </ItemInfoSheet>
    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <Paginator
          :total="applicantsTotal"
          :store="'convo/fetchApplicants'"
          @loading="setLoading"
        />
        <div>
          <select
            class="form-select"
            @change="setPerPage"
          >
            <option value="15">
              15 items per page
            </option>
            <option value="50">
              50 items per page
            </option>
            <option value="75">
              75 items per page
            </option>
            <option value="10000">
              All items per page
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'
import Paginator from '@/views/back/partials/Paginator.vue'
import Vue from 'vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCollapse,
    ItemInfoSheet,
    vSelect,
    Paginator,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
  },
  data() {
    return {
      area: null,
      evaluator: null,
      filteredEvaluators: [],
      selectedItems: [],
      selectedItem: {},
      selectedEvaluators: [],
      loading: true,
      showEvaluators: false,
      visibleAll: false,
      loadingEvaluators: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      evaluators: 'convo/evaluators',
      evaluatorsTotal: 'convo/evaluatorsTotal',
      applicantsTotal: 'convo/applicantsTotal',
      applicants: 'convo/applicants',
      fields: 'modals/tableSettingsFields',
      visible: 'modals/infoSheet',
      areas: 'areas/areas',
    }),
    pendingEvaluators() {
      return this.evaluators.filter(e => e.assignedApplicants).length
    },
    selectedArea() {
      return this.areas.find(f => f.code === this.selectedItem.area) ?? []
    },
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.selectedItem = {}
        this.selectedEvaluators = []
      }
    },
  },
  async mounted() {
    this.$store.dispatch('pagination/clearPage')
    this.$store.dispatch('pagination/clearPerPage')
    this.resetCard()
  },
  methods: {
    async reorderAlphabetical(order) {
      this.loading = true
      await this.$store.dispatch('convo/reorderApplicants', !order)
      this.loading = false
    },
    showAll(visibility) {
      this.visibleAll = !visibility
      setTimeout(() => {
        this.visibleAll = visibility
      }, 50)
    },
    filterEvaluator(event) {
      this.loading = true
      if (event) {
        this.$store.dispatch('convo/fecthApplicantsByEval', { id: this.$route.params.id, user_id: event.id }).then(() => { this.loading = false })
      } else {
        this.resetCard()
      }
    },
    customFilter(option, query) {
      // Perform case-insensitive and accent-insensitive matching
      return this.applicants.filter(e => e.full_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query))
    },
    filterCandidate(event) {
      this.loading = true
      this.$store.dispatch('convo/fetchApplicants', { area: this.area, status: { value: 996 }, candidate: event }).then(() => { this.loading = false })
    },
    async filterEval(area) {
      this.loadingEvaluators = true
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/filter-evaluators`, { area })
      this.filteredEvaluators = resp.data.data
      this.loadingEvaluators = false
    },
    selectEvaluators(action) {
      if (action) {
        this.selectedEvaluators = this.evaluators.map(e => e.id)
      } else {
        this.selectedEvaluators = []
      }
    },
    async filter() {
      this.loading = true
      this.$store.dispatch('convo/fetchApplicants', { area: this.area, status: { value: 996 } })
      await this.$store.dispatch('convo/filterEvaluators', { area: this.area })
      this.showEvaluators = true
      this.loading = false
    },
    async setPerPage(perPage) {
      this.setLoading(true)
      this.$store.dispatch('pagination/savePerPage', perPage.target.value)
      await this.$store.dispatch('convo/fetchApplicants', { area: this.area, status: { value: 996 } })
      this.setLoading(false)
    },
    hideAssigneds(event) {
      if (event.target.checked) {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 999 } })
      } else {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 996 } })
      }
    },
    async resetCard() {
      await this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 996 } })
      this.loading = false
      this.selectedItems = []
      this.selectedItem = {}
      this.selectedEvaluators = []
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    removeEvaluator(applicant, evaluator) {
      this.selectedItem = applicant
      this.selectedEvaluators = applicant.evaluator_ids.filter(e => e !== evaluator)
      this.applyActions()
    },
    editSingle(data) {
      this.filterEval(data.area)
      this.selectedItem = data
      this.selectedEvaluators = data.evaluator_ids
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      if (this.areas.length === 0) {
        this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
      }
    },
    async syncronize() {
      this.loading = true
      await this.$store.dispatch('convo/syncronizeData', { id: this.$route.params.id })
      await this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 996 } })
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 100)
      this.loading = false
    },
    applyActions() {
      if (this.selectedItems.length > 0 || this.selectedItem) {
        Vue.swal({
          title: 'Do you want to perform assigment?',
          html: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, assign!',
        }).then(result => {
          if (result.isConfirmed) {
            let applicants = null
            if (this.selectedItems.length > 0) {
              applicants = this.selectedItems
            } else {
              applicants = [this.selectedItem]
            }

            this.loading = true
            const outside = this

            this.$store.dispatch('convo/assignApplicants', {
              convoId: this.convo.id,
              applicants,
              evaluators: this.selectedEvaluators,
              sync: true,
            }).then(() => {
              outside.resetCard()
            })

            this.$store.dispatch('modals/toggleInfoItemSheet', false)
          }
        })
      } else {
        this.$toastr.error('', 'Please, select applicants before applying')
      }
    },
    setLoading(loading) {
      this.loading = loading
    },
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        search.candidate = search.candidate.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
